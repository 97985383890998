import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button, Nav, Card } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';
import Tab from 'react-bootstrap/Tab';
import { gsap } from 'gsap'
import logo from '../../../assets/regal_images/regal_logo.png'
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as HomeActions from "../../../actions/HomeActions";
import * as MovieAction from "../../../actions/MovieAction";
import { useDispatch, useSelector } from 'react-redux';
import { IMAGE_ENDPOINT, LOCALITY_IMAGE_ENDPOINT } from '../../../constant/ApiList';
import { Avatar, Box, CircularProgress, Grid, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { FacebookShareButton, FacebookIcon } from 'react-share';
import Share from '../common/Share';
// import videotest from '../../../assets/video/sample-video.mp4'
import VideoJS from '../../../player/player'
import videojs from 'video.js';
// import * as videoButtons from 'videojs-seek-buttons'
import { ROUTES } from '../../../constant/routes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
// require("@silvermine/videojs-quality-selector")(videojs);
import trending1 from '../../../assets/images/trending/01.jpg'
import plus from '../../../assets/regal_images/plus.png'
import check from '../../../assets/regal_images/check.png'
import { toast } from 'react-toastify';
import AlertMessage from '../common/AlertMessage';
import RazorpayButton from '../../../razorpay/razorpayButton'
import playIcon from '../../../assets/regal_images/play-button.png'
import Modal from '@mui/material/Modal';
import rent_icon from '../../../assets/regal_images/Rent (1).png'
import axios from 'axios';
import unmute from '../../../assets/regal_images/volume.png'
import silent from '../../../assets/regal_images/mute.png'
import Helmet from 'react-helmet';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "70vh",
    width: 400,
    bgcolor: 'rgb(25,25,25)',
    border: '2px solid rgb(255,255,255,0.5)',
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
};

const styleforAlert = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "70vh",
    width: 600,
    bgcolor: 'rgb(25,25,25)',
    border: '2px solid rgb(255,255,255,0.5)',
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
}

// install Swiper modules
SwiperCore.use([Navigation]);


const AddMovie = () => {
    const playerRef = React.useRef(null);
    const [toggler1, setToggler1] = useState(false);
    const parameter = useParams();
    const [movieData, setMovieData] = useState("")
    const dispatch = useDispatch()
    const { getMovieDetails, addtoRedirectCount } = bindActionCreators(HomeActions, dispatch)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const history = useHistory()
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [save, setSave] = useState(false)
    const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
    const [popupOpen, setPopupOpen] = useState(false)
    const handlePopupClose = () => {
        setPopupOpen(false);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'))

    const { addIntoWatchList } = bindActionCreators(MovieAction, dispatch)
    const { addToLikedContent } = MovieAction
    const { removeFromWatchlist, setCompleteHours } = MovieAction
    const [ip, setIP] = useState("")
    const movie_data = useSelector((state) => state?.home?.movieDetails)
    console.log("movie_data", movie_data)
    const [hoursAlert, setHoursAlert] = useState(false)
    const [completedHour, setCompletedHour] = useState(false)
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [error, setError] = useState(null);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [hoursLeft, setHoursLeft] = useState(calculateHoursLeft());

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const getData = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        setIP(res.data.ip)
        console.log(res)

    };
    useEffect(() => {
        (async () => {
            getData();
        })();
    }, []);


    useEffect(() => {
        (async () => {

            document.body.scrollTop = document.documentElement.scrollTop = 0;

            if (parameter?.slug) {
                const res = await axios.get("https://api.ipify.org/?format=json");

                const resData = await getMovieDetails(parameter?.slug)
                setMovieData(resData)
                console.log(resData)
            }

            const intervalId = setInterval(() => {
                setHoursLeft(calculateHoursLeft());
            }, 1000);

            // Clear the interval when the component is unmounted
            return () => clearInterval(intervalId);

        })();
    }, [save, parameter?.slug])
    // useEffect(() => {
    //     const getLocation = () => {
    //         if (navigator.geolocation) {
    //             navigator.geolocation.getCurrentPosition(
    //                 (position) => {
    //                     setLatitude(position.coords.latitude);
    //                     setLongitude(position.coords.longitude);
    //                 },
    //                 (error) => {
    //                     setError(error.message);
    //                 }
    //             );
    //         } else {
    //             setError('Geolocation is not supported by this browser.');
    //         }
    //     };

    //     getLocation();
    // }, []);
    // console.log(latitude , longitude)
    function calculateHoursLeft() {
        const now = new Date();
        const nextDay = new Date(now);
        nextDay.setDate(now.getDate() + 1);
        nextDay.setHours(0, 0, 0, 0);

        const timeDifference = nextDay - now;
        const hoursLeft = Math.floor(timeDifference / (1000 * 60 * 60));

        return hoursLeft;
    }



    const handlePlayNow = () => {
        // if (!isLoggedIn) {
        //     toast.error("Please login to continue")
        // }
        // else {
            history.push(ROUTES.video, { video: movieData.Data })
        // }


    }

    const handleHoursContinue = async () => {
        const send_status = await setCompleteHours({
            user: user_id,
            movie: movieData?.Data?.id
        })
        if (send_status?.statuscode == 200) {
            history.push(ROUTES.video, { movie: movieData, resume: movieData?.last_watching_time })
        }

    }





    const handleHoursDialogueClose = () => {
        setHoursAlert(false);
        setCompletedHour(false)
    };



    const googlemap = async () => {
        if (!isLoggedIn) {
            toast.error("Please login to continue.")
        }
        else {
            const resData = await addtoRedirectCount({ user: user_id, video: movieData?.Data?.id })
            console.log("data", resData)
            if (resData?.statuscode == 200) {
                window.open(movieData?.Data?.video_link_direction, '_blank');

            }
        }
    }

    let dict = {
        image: movieData?.Data?.video_poster.replace("/OTT/static/image/", ""),
        description: "description",
        title: "details"
    }
    let shareUrl = window.location.href
    return (
        <>
            {/* <AlertMessage /> */}
            {/* <Helmet>
                <title>tienda de ropa</title>
                <meta name="description" content="Home de la tienda" />
                <meta property="og:url" content="http://www.nytimes.com/2015/02/19/arts/international/when-great-minds-dont-think-alike.html" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="titulo para el home de la tienda" />
                <meta property="og:description" content="esta es la decripcion del home de la tienda" />
                <meta property="og:image" content="https://api.locality360.in/OTT/static/image/movies/poster/2022-03-21.jpg" />
            </Helmet> */}

            <Modal
                open={popupOpen}
                onClose={handlePopupClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {!isLoggedIn ? <Box sx={style}>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={logo} width={"100px"} />
                    </div>
                    <hr />

                    <div className="login-popup" >
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                            Please login to continue.
                        </Typography>
                        <button onClick={() => history.push("/login")} className={"btn btn-hover iq-button mt-3"} style={{ display: "block", margin: "0 auto" }}>
                            Login
                        </button>
                    </div>
                </Box> :
                    (movieData?.max_movie_watch_complete == "true" ?
                        <Box sx={styleforAlert}>

                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <img src={logo} width={"100px"} />
                            </div>
                            <hr />

                            <div className="login-popup" >
                                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                                    You have already seen this film maximum number of times. To watch it again, purchase a Diamond Plan.
                                </Typography>
                                <div className='mt-3' style={{ display: "flex", justifyContent: "center" }}>

                                    <button onClick={handlePopupClose} className={"btn btn-hover iq-button ml-2"} >
                                        Okay
                                    </button>
                                </div>
                            </div>
                        </Box> : <Box sx={style}>

                            <div style={{ display: "flex", justifyContent: "left", fontSize: "19px", color: "red", fontWeight: "900" }}>
                                {/* <img src={logo} width={"100px"} /> */}
                                Maturity Rating: 18+
                            </div>
                            <hr />

                            <div className="login-popup" >
                                <Typography id="modal-modal-title" sx={{ mt: 2, textAlign: "center" }}>
                                    May contain mature content, nudity, violence, foul language, substances.
                                </Typography>
                                <Typography id="modal-modal-title" sx={{ mt: 2, textAlign: "left" }}>
                                    I CONFIRM THAT I AM 18 YEARS AND ABOVE
                                </Typography>
                                <div className='mt-3' style={{ display: "flex", justifyContent: "end" }}>
                                    <button onClick={handlePlayNow} className={"btn btn-hover iq-button"}>
                                        OK
                                    </button>
                                    <button onClick={handlePopupClose} className={"btn btn-hover iq-button ml-2"} >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </Box>)
                }
            </Modal>
            {
                movie_data && movieData ?
                    <div>


                        <section id="home" className="iq-main-slider p-0 iq-rtl-direction" style={{marginTop:"4.5rem"}}>
                            {/* <div id="prev5" className="swiper-button swiper-button-prev"><i className="fa fa-chevron-left"></i></div>
                <div id="next5" className="swiper-button swiper-button-next"><i className="fa fa-chevron-right"></i></div> */}
                            <Swiper
                                // modules={[Autoplay, Pagination, Navigation]}
                                navigation={{
                                    prevEl: '#prev5',
                                    nextEl: '#next5'
                                }}

                                pagination={{
                                    "clickable": true
                                }}

                                autoplay={{
                                    delay: 4000,
                                }}
                                // onInit={() => { animationInit() }}
                                // onSlideChangeTransitionStart={() => animationInit()}
                                loop={false}
                                id="home-slider"
                                className="slider m-0 p-0">

                                <SwiperSlide
                                    className="slide slick-bg"
                                    id='test-id'
                                // style={{ backgroundImage: `url(${LOCALITY_IMAGE_ENDPOINT + (movieData?.Data?.video_poster)})` }}
                                >
                                    <img className='img-cover' src={LOCALITY_IMAGE_ENDPOINT + (movieData?.Data?.video_poster)} style={{ position: "absolute", top: "0", left: "0", height: "100vh", width: "100%" }} />

                                    <Container id='slider-details' fluid className="position-relative h-100">
                                        <div className="slider-inner h-100">
                                            <Row className="align-items-center  iq-ltr-direction h-100 ">
                                                <Col xl="6" lg="12" md="12" className='my-class-img'>
                                                    <div style={{ cursor: "pointer" }}>
                                                        {/* <div className="channel-logo" data-iq-delay="0.5"> */}
                                                        {/* <img src={`${IMAGE_ENDPOINT + data?.stream?.thumbnail}`} className="c-logo" alt="streamit" /> */}
                                                        <h5>{movieData?.Data?.video_name}</h5>
                                                        {/* </div> */}
                                                    </div>
                                                    {/* <Link to={`/live-streaming-details/${data?.stream?.slug}`}>
                                                    <h2 className="slider-text big-title title text-uppercase" data-iq-gsap="onStart" data-iq-position-x="-200">{data?.stream?.movie_name}</h2>
                                                </Link> */}
                                                    <div className="d-flex flex-wrap align-items-center">
                                                        <Link to={`${ROUTES.moviedetails}/${movieData?.Data?.slug}`}>

                                                            <div style={{ cursor: "pointer" }}>
                                                                <div className="d-flex flex-wrap align-items-center pt-4">
                                                                    <p>{movieData?.Data?.video_description}</p>
                                                                </div>
                                                            </div>
                                                        </Link>

                                                        {/* <div className="trending-list" data-wp_object-in="fadeInUp" data-delay-in="1.2">
                                                        <p>
                                                            <b>Streaming Date: </b><span>{data?.stream?.streaming_date}</span>
                                                        </p>
                                                     
                                                    </div> */}

                                                    </div>

                                                    <div className="d-flex align-items-start r-mb-23" style={{ marginTop: "0" }}>
                                                        <Button className="btn btn-hover iq-button" onClick={handlePlayNow}>
                                                            <i className="fa fa-play mr-2" aria-hidden="true"></i>Play
                                                        </Button>

                                                        {/* <i className="fa fa-share icon-border ml-2"></i> */}
                                                        <div className='icon-border-details ml-1' style={{ cursor: "pointer" }}>
                                                            <div className='catalosge-class' style={{ fontSize: "13px", fontWeight: "bolder" }}>₹ {(Number(movieData?.Data?.price).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>

                                                        </div>
                                                        {/* {
                                                            movieData?.Data?.catalogue !== "null" &&
                                                            <a href={movieData?.Data?.catalogue} target='_blank'>

                                                                <div className='icon-border-details ml-2'>
                                                                    <i className="fa fa-globe details-con" aria-hidden="true"></i><span className='cataloge-class'>  Catalogue</span>

                                                                </div>
                                                            </a>

                                                        } */}
                                                        {
                                                            movieData?.Data?.orders_url && movieData?.Data?.orders_url !== null && movieData?.Data?.orders_url !== ""
                                                            &&
                                                            <a href={movieData?.Data?.orders_url} target='_blank' style={{ cursor: "pointer" }}>
                                                                <i className="fa fa-globe icon-border ml-1"></i>
                                                            </a>
                                                        }
                                                        {
                                                            movieData?.Data?.location_url && movieData?.Data?.location_url !== null && movieData?.Data?.location_url !== ""
                                                            &&
                                                            <a href={movieData?.Data?.location_url} target='_blank' style={{ cursor: "pointer" }}>
                                                                <i className="fa fa-location-arrow icon-border ml-1"></i>
                                                            </a>

                                                        }

                                                        {
                                                            movieData?.Data?.mobile_number && movieData?.Data?.mobile_number !== "null" &&
                                                            <a href={`tel:${movieData?.Data?.mobile_number}`} target='_blank'>

                                                                <div className=''>
                                                                    <i className="fa fa-phone icon-border ml-1" aria-hidden="true"></i>

                                                                </div>
                                                            </a>

                                                        }

                                                        <div onClick={handleClick} style={{ cursor: "pointer" }}>
                                                            <i className="fa fa-share icon-border ml-1"></i>
                                                        </div>

                                                        <Popover
                                                            id={id}
                                                            open={open}
                                                            anchorEl={anchorEl}
                                                            onClose={handleClose}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'left',
                                                            }}
                                                        >
                                                            <Typography sx={{ p: 2 }}>
                                                                <Share sharedURL={shareUrl} poster={movieData?.Data?.video_poster} />
                                                            </Typography>
                                                        </Popover>

                                                    </div>
                                                </Col>

                                            </Row>
                                        </div>
                                    </Container>
                                </SwiperSlide>



                            </Swiper>
                        </section>




                        {/* Related movie data */}

                        <div className='main-content'>
                            <section id="iq-favorites">
                                <Container fluid>
                                    <Row>
                                        <Col sm="12" className="overflow-hidden">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h4 className="main-title">Related Videos</h4>
                                                {/* <Link className="iq-view-all" to="/movie-category">View All</Link> */}
                                            </div>
                                            <div id="favorites-contens">
                                                {/* <div id="prev" className="swiper-button swiper-button-prev"><i className="fa fa-chevron-left"></i></div>
                                    <div id="next" className="swiper-button swiper-button-next"><i className="fa fa-chevron-right"></i></div> */}
                                                <Swiper
                                                    navigation={{
                                                        prevEl: '#prev',
                                                        nextEl: '#next'
                                                    }}
                                                    breakpoints={{
                                                        320: { slidesPerView: 1 },
                                                        550: { slidesPerView: 2 },
                                                        991: { slidesPerView: 3 },
                                                        1400: { slidesPerView: 4 }
                                                    }}
                                                    loop={false}
                                                    slidesPerView={6}
                                                    spaceBetween={0}
                                                    as="ul"
                                                    className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction">

                                                    {
                                                        movieData?.Related_Movie?.map((data, index) => (
                                                            <SwiperSlide as="li" key={index} style={{ cursor: "pointer" }} >
                                                                <div onClick={() => history.push(ROUTES.moviedetails + '/' + data?.slug+ `/image-thumb/${LOCALITY_IMAGE_ENDPOINT}${data?.video_poster}`)}>
                                                                    <div onClick={() => history.push(ROUTES.moviedetails + '/' + data?.slug)} className="image-container">

                                                                        <img src={`${LOCALITY_IMAGE_ENDPOINT}/${data?.video_poster}`} className={`poster-img `} alt={data?.video_name} />
                                                                        <div className="image-overlay">{data?.video_name}</div>
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                        ))
                                                    }


                                                </Swiper>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </section>
                        </div>
                    </div>
                    :
                    <div style={{ display: "flex", justifyContent: "center", height: "100vh", alignItems: "center" }}>
                        <CircularProgress />
                    </div>
            }
            <Modal
                open={hoursAlert}
                onClose={(handleHoursDialogueClose)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={styleforAlert}>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={logo} width={"100px"} />
                    </div>
                    <hr />

                    <div className="login-popup" >
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                            Once you start watching this movie, you have 72 hours to finish it; if not, you must rent it again.
                        </Typography>
                        <div style={{ display: "flex", justifyContent: "end", marginTop: "1.5rem" }}>

                            <button onClick={handleHoursContinue} className={"btn btn-hover iq-button"}>
                                Watch Now
                            </button>
                            <button onClick={handleHoursDialogueClose} className={"btn btn-hover ml-3 iq-button"}>
                                Close
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={completedHour}
                onClose={(handleHoursDialogueClose)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={styleforAlert}>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={logo} width={"100px"} />
                    </div>
                    <hr />

                    <div className="login-popup" >
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                            You have exceeded 72 hours of movie watching limitation , please rent now to continue                         </Typography>
                        <div style={{ display: "flex", justifyContent: "end", marginTop: "1.5rem" }}>
                            <button onClick={(handleHoursDialogueClose)} className={"btn btn-hover ml-3 iq-button"}>
                                Close
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>

        </>
    )
}
export default AddMovie;

