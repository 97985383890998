import { Autocomplete, Paper, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import PhoneInput from 'react-phone-input-2';
import styles from "../../../../views/backend/pages/auth/form.module.css";
import Chip from '@mui/material/Chip';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import * as HomeAction from '../../../../actions/HomeActions'
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ROUTES } from '../../../../constant/routes';

export default function DigitalForm() {

    const [formData, setFormData] = useState({
        location_url: "",
        orders_url: ""
    })
    const top100Films = [
        { title: 'Fight Club', label: 'The Shawshank Redemption', year: 1994 },
        { title: 'Fight', label: 'The Godfather', year: 1972 },
        { title: 'Fight', label: 'The Godfather', year: 1972 }

    ]
    const fixedOptions = [top100Films[6]];
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id

    const [value, setValue] = React.useState([]);
    const [value1, setValue1] = React.useState([]);

    const [tags, settags] = useState([])
    const [language, setLanguage] = useState([])

    const [category, setCategory] = useState([])
    const [subCategory, setSubCategory] = useState([])
    const [otherData, setOtherData] = useState("")
    const dispatch = useDispatch()
    const { getAllCategories, getSubCategory, submitDigitalForm } = bindActionCreators(HomeAction, dispatch)
    const { getAllData } = HomeAction
    const history = useHistory()
    useEffect(() => {
        (async () => {
            const resData = await getAllCategories()
            const res = await getAllData()

            console.log(res)
            setOtherData(res)
            settags(res?.tags?.data)
            setLanguage(res?.language?.data)
            setCategory(resData?.data)
        })();
    }, [])
    console.log(formData)

    const handleSubcategory = async (data) => {
        const resData = await getSubCategory({ id: data?.id })
        console.log(resData)
        setSubCategory(resData?.data)

    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        formData.user = user_id
        const formlist = new FormData()
        Object.keys(formData).map((key) => formlist.append(key, formData?.[key]));
        if (formData?.description &&
            formData?.product_category &&
            formData?.product_image &&
            formData?.product_name &&
            formData?.product_price &&
            formData?.product_subcategory &&
            formData?.product_tag &&
            formData?.video_url
        ) {
            const resData = await submitDigitalForm(formlist)
            console.log(resData)
            if (resData?.statuscode == 200) {
                toast.success(resData?.message)
                setTimeout(() => {
                    history.push(ROUTES.home)
                }, 2000)
                setFormData({})
            }
            else {
                toast.error(resData?.message)
            }
        }
        else {
            toast?.error("Please fill out the required fields.")
        }



    }
    return (
        <section className="m-profile setting-wrapper">
            <Container style={{ border: "1px solid #404043", padding: "40px", borderRadius: "1rem" }}>
                <h5>Digital Form for Product Submission</h5>
                <form onSubmit={handleSubmit}>

                    <Row className='mt-3'>
                        <Col md="6">
                            <Form.Group>
                                {/* <Form.Label style={{ color: "white" }}>First Name</Form.Label> */}
                                <Form.Control
                                    type="text"
                                    className="mb-0"
                                    id="exampleInputEmail2"
                                    onChange={(event) => (setFormData({ ...formData, product_name: event.target.value }))}
                                    placeholder="Product Name"
                                    autoComplete="off"

                                    style={{ height: '55px' }} // Set the desired height

                                />

                            </Form.Group>
                        </Col>
                        <Col md="6">
                            <Form.Group>
                                {/* <Form.Label style={{ color: "white" }}>Last Name</Form.Label> */}
                                <Form.Control
                                    type="text"
                                    className="mb-0"
                                    id="exampleInputEmail3"
                                    onChange={(event) => (setFormData({ ...formData, product_price: event.target.value }))}
                                    placeholder="Product Price"
                                    autoComplete="off"

                                    style={{ height: '55px' }} // Set the desired height

                                />

                            </Form.Group>
                        </Col>
                        {/* <Col md="4">
                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    className="mb-0"
                                    id="exampleInputEmail3"
                                    onChange={(event) => (setFormData({ ...formData, email: event.target.value }))}
                                    placeholder="Email"
                                    autoComplete="off"
                                    
                                    style={{ height: '55px' }} // Set the desired height

                                />

                            </Form.Group>
                        </Col> */}

                        {/* <Col md="6">
                            <Form.Group>
                                <PhoneInput
                                    labelId="demo-simple-select-label"
                                    country={"in"}
                                    onChange={(phone, countryCode) => {
                                        setFormData({
                                            ...formData,
                                            mobile_number:
                                                (countryCode.dialCode == "91" ? (phone[2] >= 6 ? phone.substring(2) : countryCode.dialCode)
                                                    : phone.substring(2)),
                                            countryCode: "+" + countryCode.dialCode,
                                        });
                                    }}
                                    isValid={(value, country) => {
                                        if (country.dialCode == "91" && value[2] < 6) {
                                            return 'Invalid Number'
                                        } else {
                                            return true;
                                        }
                                    }}
                                    dropdownClass={styles.phoneInputDropDown}
                                    highlightClass={styles.highlight}
                                    buttonClass={styles.phoneInputButton}
                                    inputClass={styles.phoneInput}
                                />
                            </Form.Group>
                        </Col>
                       

                        <Col md="6">
                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    className="mb-0"
                                    id="exampleInputEmail2"
                                    onChange={(event) => (setFormData({ ...formData, store_name: event.target.value }))}
                                    placeholder="Store Name"
                                    autoComplete="off"
                                    
                                    style={{ height: '48px' }} // Set the desired height

                                />

                            </Form.Group>
                        </Col> */}
                        {/* <Col md="6">
                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    className="mb-0"
                                    id="exampleInputEmail3"
                                    onChange={(event) => (setFormData({ ...formData, video_name: event.target.value }))}
                                    placeholder="Video Name"
                                    autoComplete="off"
                                    
                                    style={{ height: '55px' }}

                                />

                            </Form.Group>
                        </Col> */}
                        <Col md="6">
                            <Form.Group>
                                {/* <Form.Label style={{ color: "white" }}>Last Name</Form.Label> */}
                                <Form.Control
                                    type="text"
                                    className="mb-0"
                                    id="exampleInputEmail3"
                                    onChange={(event) => (setFormData({ ...formData, video_url: event.target.value }))}
                                    placeholder="Video URL"
                                    autoComplete="off"

                                    style={{ height: '55px' }} // Set the desired height

                                />

                            </Form.Group>
                        </Col>
                        <Col md="6">
                            <Form.Group>
                                {/* <Form.Label style={{ color: "white" }}>Last Name</Form.Label> */}
                                <Form.Control
                                    type="text"
                                    className="mb-0"
                                    id="exampleInputEmail3"
                                    onChange={(event) => (setFormData({ ...formData, location_url: event.target.value }))}
                                    placeholder="Google map location (Optional)"
                                    autoComplete="off"

                                    style={{ height: '55px' }} // Set the desired height

                                />

                            </Form.Group>
                        </Col>
                        <Col md="6">
                            <span style={{ fontSize: "10px", color: "red" }}>You can Upload your video file to Google Drive, VIMEO, WETRANSFER, or any other online platform. After you upload the video, a link will be generated, which you must copy and paste into the text box above.</span>

                        </Col>
                        <Col md="6">
                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    className="mb-0 mt-2"
                                    id="exampleInputEmail3"
                                    onChange={(event) => (setFormData({ ...formData, orders_url: event.target.value }))}
                                    placeholder="Orders URL (Optional)"
                                    autoComplete="off"

                                    style={{ height: '55px' }} // Set the desired height

                                />

                            </Form.Group>
                        </Col>
                        <Col md="6" className='mt-3'>

                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={category}
                                getOptionLabel={(option) => option.category_name}
                                onChange={(event, value) => {
                                    if (value !== null) {
                                        setFormData({ ...formData, product_category: value?.id });
                                        handleSubcategory(value)
                                    }
                                }}
                                sx={{

                                    borderRadius: "1rem",

                                }}
                                PaperComponent={({ children }) => (
                                    <Paper style={{ backgroundColor: 'black', color: "#ffffff" }}>{children}</Paper>
                                )}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Product Category"
                                        inputProps={{
                                            ...params.inputProps,
                                            style: { borderColor: 'white', color: 'white' }, // Change border and value color
                                        }}
                                        InputLabelProps={{
                                            ...params.InputLabelProps,
                                            style: { color: 'white' }, // Change label color
                                        }}
                                    />}
                            />

                        </Col>
                        <Col md="6" className='mt-3'>
                            <Form.Group>
                                {/* <Form.Label style={{ color: "white" }}>Last Name</Form.Label> */}
                                <Autocomplete
                                    multiple
                                    id="fixed-tags-demo"
                                    onChange={(event, newValue) => {
                                        if (newValue !== null) {
                                            setValue(newValue);
                                            const selectedIds = newValue.map(option => option.id);
                                            setFormData({ ...formData, product_subcategory: selectedIds });
                                        }
                                    }}
                                    value={value}

                                    PaperComponent={({ children }) => (
                                        <Paper style={{ backgroundColor: 'black', color: "#ffffff" }}>{children}</Paper>
                                    )}
                                    options={subCategory}
                                    getOptionLabel={(option) => option?.subcategory_name}
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => (
                                            <Chip
                                                label={option?.subcategory_name}
                                                {...getTagProps({ index })}
                                                sx={{
                                                    bgcolor: '#7c7c7e', // Change chip background color
                                                    color: 'black', // Change chip text color
                                                }}
                                            />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Sub Categories"
                                            placeholder="Select subcategories"
                                            inputProps={{
                                                ...params.inputProps,
                                                style: { borderColor: 'white', color: 'white' }, // Change border and value color
                                            }}
                                            InputLabelProps={{
                                                ...params.InputLabelProps,
                                                style: { color: 'white' }, // Change label color
                                            }}
                                        />
                                    )}
                                />
                            </Form.Group>
                        </Col>

                        <Col md="6">
                            <Form.Group>
                                {/* <Form.Label style={{ color: "white" }}>Last Name</Form.Label> */}
                                <Autocomplete
                                    multiple
                                    id="fixed-tags-demo"
                                    value={value1}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ backgroundColor: 'black', color: "#ffffff" }}>{children}</Paper>
                                    )}
                                    onChange={(event, newValue) => {
                                        if (newValue !== null) {
                                            setValue1(newValue);
                                            const selectedIds = newValue.map(option => option.id);
                                            setFormData({ ...formData, product_tag: selectedIds });
                                        }
                                    }}
                                    options={tags}
                                    getOptionLabel={(option) => option?.tag_title}
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => (
                                            <Chip
                                                label={option?.tag_title}
                                                {...getTagProps({ index })}
                                                sx={{
                                                    bgcolor: '#7c7c7e', // Change chip background color
                                                    color: 'black', // Change chip text color
                                                }}
                                            />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Tags"
                                            placeholder="Select tags"
                                            inputProps={{
                                                ...params.inputProps,
                                                style: { borderColor: 'white', color: 'white' }, // Change border and value color
                                            }}
                                            InputLabelProps={{
                                                ...params.InputLabelProps,
                                                style: { color: 'white' }, // Change label color
                                            }}
                                        />
                                    )}
                                />
                            </Form.Group>
                        </Col>

                        {/* <Col md="6">

                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={language}
                                getOptionLabel={(option) => option.language_name}
                                onChange={(event, value) => {
                                    if (value !== null) {
                                        setFormData({ ...formData, video_language: value?.id });
                                    }
                                }}
                                PaperComponent={({ children }) => (
                                    <Paper style={{ backgroundColor: 'black', color: "#ffffff" }}>{children}</Paper>
                                )}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Language"
                                        inputProps={{
                                            ...params.inputProps,
                                            style: { borderColor: 'white', color: 'white' }, // Change border and value color
                                        }}
                                        InputLabelProps={{
                                            ...params.InputLabelProps,
                                            style: { color: 'white' }, // Change label color
                                        }}
                                    />}
                            />

                        </Col>
                        <Col md="6">
                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    className="mb-0"
                                    id="exampleInputEmail3"
                                    onChange={(event) => (setFormData({ ...formData, target_location: event.target.value }))}
                                    placeholder="Store Location Url ( Optional )"
                                    autoComplete="off"
                                    style={{ height: '55px' }} // Set the desired height

                                />

                            </Form.Group>
                        </Col> */}
                        {/* <Col md="6">
                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    className="mb-0"
                                    id="exampleInputEmail3"
                                    onChange={(event) => (setFormData({ ...formData, catalogue: event.target.value }))}
                                    placeholder="Catalogue URL ( Optional )"
                                    autoComplete="off"
                                    style={{ height: '55px' }} // Set the desired height

                                />

                            </Form.Group>
                        </Col> */}
                        <Col md="6" >
                            <Col md="12" >

                                <Form.Group controlId="formFile" className="mb-3" style={{ display: "flex", justifyContent: "end" }}>

                                    <div id="file-upload-form" className="uploader">
                                        <input id="file-upload" type="file" name="fileUpload" accept="image/*"
                                            onChange={(event) => {
                                                const selectedFile = event.target.files[0]; // Get the selected file
                                                setFormData({ ...formData, product_image: selectedFile }); // Update the state with the selected file
                                            }}
                                        />

                                        <label for="file-upload" id="file-drag">
                                            <img id="file-image" src="#" alt="Preview" className="hidden" />
                                            <div id="start">
                                                {/* <i className="fa fa-download" aria-hidden="true"></i> */}
                                                <div className='mt-3'>Upload product image / Logo</div>
                                                {/* <div id="notimage" className="hidden">Please select an image</div> */}
                                                <span id="file-upload-btn" className="btn btn-primary">Select Image</span><br />
                                                <span id="file-upload-btn" className="">{formData?.product_image?.name}</span>
                                                <span style={{ fontSize: "15px", color: "red", display: "flex", justifyContent: "center" }}>Image size should be 1920px x 1080px</span>


                                            </div>
                                            <div id="response" className="hidden">
                                                <div id="messages"></div>
                                                <progress className="progress" id="file-progress" value="0">
                                                    <span>0</span>%
                                                </progress>
                                            </div>
                                        </label>
                                    </div>

                                </Form.Group>

                            </Col>
                        </Col>

                        <Col md="12" >
                            <Form.Group style={{ width: "100%" }}>
                                <Form.Label style={{ display: "flex", color: "white" }}> Description</Form.Label>

                                <Form.Control
                                    as="textarea"
                                    name='description'
                                    id="exampleInputEmail2"
                                    onChange={(event) => (setFormData({ ...formData, description: event.target.value }))}

                                ></Form.Control>


                            </Form.Group>
                        </Col>


                        <Button type='submit' style={{ display: "block", margin: "0 auto" }} className="btn btn-hover" >Submit</Button>
                    </Row>
                </form>

            </Container>
        </section>
    )
}
