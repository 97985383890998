import React, { useState, useEffect, useMemo } from 'react'
import { Container, Button, Row, Col, Form } from 'react-bootstrap'
import InputGroup from 'react-bootstrap/InputGroup';
import { Link, useHistory } from 'react-router-dom'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import FormControl from "@mui/material/FormControl";
import styles from "./form.module.css";
import "./form.css"
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { rtlModeAction, getRtlMode } from '../../../../store/mode/rtlmode'
import { useDispatch } from 'react-redux';
import { register, otp, resend_otp } from "../../../../actions/HomeActions"
import { useSelector } from 'react-redux';
import axios from "axios";
import OtpInput from 'react-otp-input';
import Alert from 'react-bootstrap/Alert';
import { Autocomplete, CircularProgress, FormControlLabel, Paper, Radio, RadioGroup, TextField } from '@mui/material';
import { ROUTES } from '../../../../constant/routes';
import { toast } from 'react-toastify';
import * as HomeAction from '../../../../actions/HomeActions'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
let City = require('country-state-city').City;
let State = require('country-state-city').State;

const mapStateToProps = (state) => {
    return {
        rtlMode: getRtlMode(state)
    };


}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            rtlModeAction
        },
        dispatch
    )
})
const SignUp = (props) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const [form, setForm] = useState({})
    const [messageTimeout, setMessageTimeout] = useState(false)
    const [seconds, setSeconds] = useState(0);
    const [nextInfo, setNextInfo] = useState(location?.state?.role ? true : false);
    const [sellerData, setSellerData] = useState({})
    const [role, setRole] = useState(location?.state?.role ? location?.state?.role : 'customer');
    const { SellerReg, submitBankData } = bindActionCreators(HomeAction, dispatch)
    const { getBankData } = HomeAction
    const [selectedState, setSelectedState] = useState({})
    const [sellerInfo, setSellerInfo] = useState({})
    const [BankInfo, setBankInfo] = useState({})
    const resendOTPDuration = 60
    useEffect(() => {
        if (seconds <= 0) {
            return;
        }

        // Set up the timer
        const timer = setInterval(() => {
            setSeconds((prevSeconds) => prevSeconds - 1);
        }, 1000);

        // Clean up the timer
        return () => clearInterval(timer);
    }, [seconds]);
    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60)
            .toString()
            .padStart(2, '0');
        const seconds = (timeInSeconds % 60).toString().padStart(2, '0');
        return `${minutes}:${seconds}`;
    };
    let history = useHistory()
    const [show, setShow] = useState(false);

    useEffect(() => {
        const rtlMode = sessionStorage.getItem('rtl-mode');
        if (rtlMode === null) {
            props.rtlModeAction(props.rtlMode)
        }
        else {
            props.rtlModeAction(rtlMode);
        }
    })
    const register_message = useSelector((state) => state.home.register_message);
    const otp_message = useSelector((state) => state.home.otp_message);

    const [fieldFlag, setFieldFlag] = useState({ email: false, firstName: false, lastName: false, password: false });
    const [loader, setLoader] = useState(false)
    const getData = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        setForm({ ...form, ip_address: res.data.ip });
    };
    useEffect(() => {
        getData();
    }, []);
    const handleRegistrationSubmit = (event) => {
        event.preventDefault();
        if (role == "customer") {
            if (form?.email == undefined || form?.email == "" || !form?.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                setFieldFlag({ ...fieldFlag, email: true })
            }

            else if (form?.firstName == undefined || form?.firstName == "") {
                setFieldFlag({ ...fieldFlag, email: false, firstName: true })

            }
            else if (form?.lastName == undefined || form?.lastName == "") {
                setFieldFlag({ ...fieldFlag, email: false, firstName: false, lastName: true })

            }
            else if (form?.state == undefined || form?.city == undefined || form?.mobileNumber == undefined || form?.pincode == undefined || form?.gender == undefined) {
                toast.error("Please filled out the required fiels")
            }
            else if (form?.password == undefined || form?.password == "") {
                setFieldFlag({ ...fieldFlag, email: false, firstName: false, lastName: false, birthDate: false, password: true })

            }
            else if (form?.password !== form?.rpassword) {
                // setFieldFlag({ ...fieldFlag, email: false, firstName: false, lastName: false, birthDate: false, password: true })
                toast.error("Password and confirm password must be same")
            }
            else {
                if (form?.password?.length >= 6) {
                    dispatch(register({ ...form, username: form?.mobileNumber }));
                    setLoader(true)
                }
                else {
                    toast.error("Password must be at least 6 character long.")
                }

            }
        }
        else {
            toast.error("Something went wrong")
        }



    }

    const [otpVerification, setOtpVerification] = useState(false);
    useMemo(() => {

        setMessageTimeout(true)
        if (register_message?.statuscode == 200) {
            setLoader(false)
            console.log(register_message)
            setOtpVerification(true)
            setSeconds(resendOTPDuration)
        }
        else {
            setLoader(false)

        }

        setTimeout(() => { setMessageTimeout(false) }, 2000)
    }, [register_message])
    useMemo(() => {

        setMessageTimeout(true)
        if (otp_message?.statuscode == 200 && form?.resend == false) {

            setTimeout(() => {
                history.push('/login')

            }, 1500)
        }
        setTimeout(() => { setMessageTimeout(false) }, 2000)


    }, [otp_message])

    const handleOTPSubmit = (event) => {
        event.preventDefault();
        // sessionStorage.setItem("user", JSON.stringify({ id: register_message?.User_ID, mobileNumber: register_message?.mobileNumber, email: register_message?.email,birthDate:register_message?.birth_date }))
        // sessionStorage.setItem("isLoggedIn", true)
        console.log(register_message)
        setForm({ ...form, resend: false })
        if (role == "seller") {
            console.log(role, sellerData)
            form.email_id = location?.state?.email ? location?.state?.email : sellerData?.email
        }
        form.flag = role
        setTimeout(() => {
            dispatch(otp({ ...form, mobileNumber: register_message?.mobileNumber, email: register_message?.email }));
        }, 1000);
    }
    const handleResendOTPSubmit = (event, resend) => {
        event.preventDefault();
        if (role == "seller") {
            console.log(sellerData)
            dispatch(resend_otp({ ...sellerData, username: sellerData?.mobileNumber, flag: role }));
            setSellerData({ ...sellerData, resend: resend })

        }
        else {
            console.log("else pa")
            dispatch(resend_otp({ ...form, username: form?.mobileNumber, flag: role }));
            setForm({ ...form, resend: resend })


        }
        setSeconds(resendOTPDuration)
    }

    const handleNextInfo = async () => {
        if (sellerData?.firstName &&
            sellerData?.lastName &&
            sellerData?.email &&
            sellerData?.mobileNumber &&
            sellerData?.annual_turnover &&
            sellerData?.gst_no &&
            sellerData?.address &&
            sellerData?.password &&
            sellerData?.rpassword &&
            sellerData?.gender &&
            sellerData?.state &&
            sellerData?.city &&
            sellerData?.pincode
        ) {
            if (sellerData?.password?.length >= 6) {
                if (sellerData?.password == sellerData?.rpassword) {
                    sellerData.places_of_business = "Ahmedabad,Mumbai"
                    const resData = await SellerReg(sellerData)
                    console.log("Seller", resData)
                    if (resData?.statuscode == 200) {
                        setSellerInfo(resData)
                        setNextInfo(true)
                    }
                    else {
                        toast.success(resData?.message)
                    }
                }
                else {
                    toast.error("Password and confirm password must be same")
                }
            }
            else {
                toast.error("Password must be at least 6 character long")
            }

        }
        else {
            toast.error("All fields are required")
        }

    }
    const getBankInfo = async () => {
        if (sellerData?.ifsc_code) {
            const resData = await getBankData(sellerData)
            if (resData?.statuscode == 200) {
                setBankInfo(resData)
            } else {
                toast.error("Something went wrong")
            }
        }
    }

    const submitBankInfo = async () => {
        sellerData.bank_name = BankInfo?.data?.BANK
        sellerData.branch_name = BankInfo?.data?.BRANCH
        sellerData.bank_address = BankInfo?.data?.ADDRESS
        sellerData.micr_no = BankInfo?.data?.MICR
        sellerData.user_id = location?.state?.user ? location?.state?.user : sellerInfo?.User_ID
        if (sellerData?.ifsc_code &&
            sellerData?.acc_holder_name &&
            sellerData?.acc_number &&
            sellerData?.confirm_acc_number

        ) {
            const formlist = new FormData()
            Object.keys(sellerData).map((key) => formlist.append(key, sellerData?.[key]));
            if (sellerData?.acc_number == sellerData?.confirm_acc_number) {
                const resData = await submitBankData(formlist)
                console.log("Seller", resData)
                if (resData?.statuscode == 200) {
                    toast?.success(resData?.message)
                    setTimeout(() => {
                        setOtpVerification(true)
                    }, 1500)
                }
                else {
                    toast.error(resData?.message)
                }
            }
            else {
                toast.error("Account number and confirm account number must be same")
            }

        }
        else {
            toast.error("All fields are required")
        }
    }

    const businessType = [
        { label: 'Manufacturer' },
        { label: 'Trader' },
        { label: 'Importer' },
        { label: 'Made to order' }

    ]

    console.log(sellerData, form)

    const stateCity = selectedState ? City.getCitiesOfState('IN', selectedState.isoCode) : [];

    return (
        <>
            <section className="sign-in-page" style={{ marginTop: "5rem" }}>
                <Container >
                    <Row className="justify-content-center align-items-center height-self-center" >
                        <Col lg="10" md="12" className="align-self-center">
                            <div className="sign-user_card ">
                                <div className="sign-in-page-data">
                                    <div className="sign-in-from w-100 m-auto">
                                        {otpVerification ? <Form onSubmit={(event) => handleOTPSubmit(event)} >
                                            <Row style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                                                <Col md="6" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <Form.Group >
                                                        <Form.Label style={{ display: "flex", justifyContent: "center", color: "white", marginBottom: "2rem" }}>Enter the 4 digit OTP</Form.Label>
                                                        <FormControl fullWidth >
                                                            <OtpInput
                                                                containerStyle={{ display: "flex", justifyContent: "space-evenly" }}
                                                                value={form?.OTP}
                                                                onChange={(a) => (setForm({ ...form, OTP: a }))}
                                                                numInputs={4}
                                                                inputStyle={{ fontSize: "3em", padding: "0", border: "none", borderRadius: "0", borderBottom: "2px solid white", background: "transparent" }}
                                                                inputType='text'
                                                                renderSeparator={<span> &nbsp;&nbsp; </span>}
                                                                renderInput={(props) => <input {...props} />}
                                                            />
                                                            {(form?.OTP == "" || form?.OTP == undefined) && <Form.Control.Feedback style={{ display: "flex", justifyContent: "center", textAlign: "center" }} type="invalid">
                                                                Please enter the OTP you have received on Email id/Mobile No
                                                            </Form.Control.Feedback>}</FormControl>
                                                    </Form.Group>
                                                </Col>
                                                {seconds > 0 ? <p>Resend OTP in {formatTime(seconds)}</p> : <p style={{ cursor: "pointer" }} onClick={(event) => {
                                                    handleResendOTPSubmit(event, true)
                                                }} >Resend OTP</p>}

                                            </Row>
                                            {otp_message?.message && messageTimeout && (otp_message?.statuscode == 200 ?
                                                <Alert variant="success" style={{ background: "rgb(0,255,0,0.1)", borderColor: "rgb(0,255,0)" }} >
                                                    <p style={{ color: "rgb(0,255,0)", fontWeight: "500", fontSize: "14px" }}>
                                                        {otp_message?.message}
                                                    </p>
                                                </Alert>
                                                : <Alert variant="danger" style={{ background: "rgb(255,0,0,0.1)", borderColor: "rgb(255,0,0)" }} >
                                                    <p style={{ color: "rgb(255,0,0)", fontWeight: "500", fontSize: "14px" }}>
                                                        {otp_message?.message}
                                                    </p>
                                                </Alert>)}
                                            <div style={{ display: "flex", justifyContent: "space-around", gap: "2rem" }}>


                                                <Button type='submit' className="btn btn-hover" >Submit</Button>
                                            </div>


                                        </Form> :
                                            <div>
                                                <Col md="12" className='mb-2' style={{ display: "flex", justifyContent: "center" }}>

                                                    <FormControl >
                                                        <h5 style={{ textAlign: "center" }}>Register as</h5>

                                                        <RadioGroup
                                                            row
                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                            name="row-radio-buttons-group"
                                                            value={role}
                                                        >
                                                            <FormControlLabel value="customer" defaultChecked={true} onClick={() => setRole('customer')} control={<Radio sx={{ color: "#ffffff" }} />} label="Customer" />
                                                            <FormControlLabel value="seller" onClick={() => setRole('seller')} control={<Radio sx={{ color: "#ffffff" }} />} label="Seller" />

                                                        </RadioGroup>
                                                    </FormControl>
                                                </Col>
                                                <Form onSubmit={(event) => handleRegistrationSubmit(event)}>
                                                    {
                                                        role == 'customer' ?
                                                            <div>
                                                                <Row className='padding-mobile'>
                                                                    <Col md="6">
                                                                        <Form.Group>
                                                                            <Form.Label style={{ color: "white" }}>First Name</Form.Label>
                                                                            <Form.Control type="text" isInvalid={fieldFlag.firstName} onChange={(event) => (setForm({ ...form, firstName: event.target.value }))} className="mb-0" id="exampleInputEmail2" placeholder="First Name" autoComplete="off" />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                Please enter the First Name.
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <Form.Group>
                                                                            <Form.Label style={{ color: "white" }}>Last Name</Form.Label>
                                                                            <Form.Control type="text" isInvalid={fieldFlag.lastName} onChange={(event) => (setForm({ ...form, lastName: event.target.value }))} className="mb-0" id="exampleInputEmail3" placeholder="Last Name" autoComplete="off" />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                Please enter the Last Name.
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <Form.Group>
                                                                            <Form.Label style={{ color: "white" }}>E-mail</Form.Label>
                                                                            <Form.Control style={{ borderRadius: "1rem" }} type="email" isInvalid={fieldFlag.email} onChange={(event) => (setForm({ ...form, email: event.target.value }))} className="mb-0" id="exampleInputEmail3" placeholder="Enter email" autoComplete="off" />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                Please enter the valid Email.
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <Form.Group>
                                                                            <Form.Label style={{ color: "white" }}>Phone</Form.Label>
                                                                            <FormControl fullWidth>
                                                                                <PhoneInput
                                                                                    labelId="demo-simple-select-label"
                                                                                    country={"in"}
                                                                                    required={true}
                                                                                    // value={form?.phoneNumber}

                                                                                    onChange={(phone, countryCode) => {
                                                                                        setForm({
                                                                                            ...form,
                                                                                            mobileNumber:
                                                                                                // form?.countryCode == "+" + countryCode.dialCode

                                                                                                (countryCode.dialCode == "91" ? (phone[2] >= 6 ? phone.substring(2) : countryCode.dialCode)
                                                                                                    : phone.substring(2)),
                                                                                            countryCode: "+" + countryCode.dialCode,
                                                                                        });
                                                                                    }}
                                                                                    isValid={(value, country) => {
                                                                                        if (country.dialCode == "91" && value[2] < 6) {
                                                                                            return 'Invalid Number'
                                                                                        } else {
                                                                                            return true;
                                                                                        }
                                                                                    }}
                                                                                    dropdownClass={styles.phoneInputDropDown}
                                                                                    highlightClass={styles.highlight}
                                                                                    buttonClass={styles.phoneInputButton}
                                                                                    inputClass={styles.phoneInput}
                                                                                />
                                                                            </FormControl>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col md="4" className='mt-4'>
                                                                        <Autocomplete
                                                                            disablePortal
                                                                            id="combo-box-demo"
                                                                            options={State.getAllStates().filter(state => state.countryCode === 'IN')}
                                                                            getOptionLabel={(option) => option.name}
                                                                            onChange={(event, value) => {
                                                                                if (value !== null) {
                                                                                    // Perform actions when a state is selected
                                                                                    setForm({ ...form, state: value?.name })
                                                                                    setSelectedState(value)
                                                                                    // You can perform additional actions here, such as updating state or making API calls
                                                                                }
                                                                            }}
                                                                            sx={{

                                                                                borderRadius: "1rem",

                                                                            }}
                                                                            PaperComponent={({ children }) => (
                                                                                <Paper style={{ backgroundColor: 'black', color: "#ffffff" }}>{children}</Paper>
                                                                            )}
                                                                            renderInput={(params) =>
                                                                                <TextField
                                                                                    {...params}
                                                                                    label="Select State"
                                                                                    inputProps={{
                                                                                        ...params.inputProps,
                                                                                        style: { borderColor: 'white', color: 'white' }, // Change border and value color
                                                                                    }}
                                                                                    InputLabelProps={{
                                                                                        ...params.InputLabelProps,
                                                                                        style: { color: 'white' }, // Change label color
                                                                                    }}
                                                                                />}
                                                                        />
                                                                    </Col>
                                                                    <Col md="4" className='mt-4'>
                                                                        <Form.Group>
                                                                            <Form.Control
                                                                                type="text"
                                                                                onChange={(event) => (setForm({ ...form, city: event.target.value }))}
                                                                                className="mb-0"
                                                                                id="exampleInputEmail3"
                                                                                placeholder="Area Name"
                                                                                autoComplete="off"
                                                                                style={{ height: '55px' }} // Set the desired height

                                                                            />

                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <Form.Group>
                                                                            <Form.Label style={{ color: "white" }}></Form.Label>
                                                                            <Form.Control type="text"
                                                                                onChange={(event) => (setForm({ ...form, pincode: event.target.value }))}
                                                                                id="exampleInputEmail3"
                                                                                placeholder="PIN Code"
                                                                                autoComplete="off"

                                                                                name='pincode'
                                                                                style={{ height: '55px' }} // Set the desired height

                                                                            />

                                                                        </Form.Group>
                                                                    </Col>

                                                                    <Col md="6">
                                                                        <Form.Group>
                                                                            <Form.Label style={{ color: "white" }}>Password</Form.Label>
                                                                            <InputGroup className="mb-3">
                                                                                <Form.Control type="password" isInvalid={fieldFlag.password} onChange={(event) => (setForm({ ...form, password: event.target.value }))} className="promocode-input" id="password" placeholder="Password" />
                                                                                <Button variant="outline-secondary" onClick={(e) => {
                                                                                    let temp = document.getElementById("password")
                                                                                    if (temp.type == "password") {
                                                                                        temp.type = "text"
                                                                                        document.getElementById("password_icon").className = "fa fa-eye"
                                                                                    }
                                                                                    else {
                                                                                        temp.type = "password"
                                                                                        document.getElementById("password_icon").className = "fa fa-eye-slash"

                                                                                    }
                                                                                }} className='promocode-button' style={{ backgroundColor: "black" }}>
                                                                                    <i className="fa fa-eye-slash" id='password_icon' style={{ color: "#ffffff" }}></i>
                                                                                </Button>
                                                                                <Form.Control.Feedback type="invalid">
                                                                                    Please enter the password.
                                                                                </Form.Control.Feedback>
                                                                            </InputGroup>
                                                                        </Form.Group>
                                                                    </Col>

                                                                    <Col md="6">
                                                                        <Form.Group>
                                                                            <Form.Label style={{ color: "white" }}>Confirm Password</Form.Label>
                                                                            <InputGroup className="mb-3">
                                                                                <Form.Control type="password" isInvalid={form?.password != form?.rpassword} onChange={(event) => (setForm({ ...form, rpassword: event.target.value }))} className="promocode-input" id="confirm_password" placeholder="Password" />
                                                                                <Button variant="outline-secondary" onClick={(e) => {
                                                                                    let temp = document.getElementById("confirm_password")
                                                                                    if (temp.type == "password") {
                                                                                        temp.type = "text"
                                                                                        document.getElementById("confirm_password_icon").className = "fa fa-eye"
                                                                                    }
                                                                                    else {
                                                                                        temp.type = "password"
                                                                                        document.getElementById("confirm_password_icon").className = "fa fa-eye-slash"

                                                                                    }
                                                                                }} className='promocode-button' style={{ backgroundColor: "black", zIndex: "0" }}>
                                                                                    <i className="fa fa-eye-slash" id='confirm_password_icon' style={{ color: "#ffffff" }}></i>
                                                                                </Button>
                                                                                <Form.Control.Feedback type="invalid">
                                                                                    Passwords do not match.
                                                                                </Form.Control.Feedback>       </InputGroup>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <Form.Group>
                                                                            <Form.Label style={{ color: "white" }}>Gender</Form.Label>
                                                                            <div key={`inline-radio`} className="mb-3">
                                                                                <Form.Check

                                                                                    inline
                                                                                    label="Male"
                                                                                    name="gender"
                                                                                    type='radio'
                                                                                    id={`inline-radio-1`}
                                                                                    onClick={(event) => (setForm({ ...form, gender: "Male" }))}
                                                                                />
                                                                                <Form.Check

                                                                                    inline
                                                                                    label="Female"
                                                                                    name="gender"
                                                                                    type='radio'
                                                                                    id={`inline-radio-2`}
                                                                                    onClick={(event) => (setForm({ ...form, gender: "Female" }))}

                                                                                />
                                                                                <Form.Check

                                                                                    inline
                                                                                    label="Other"
                                                                                    name="gender"
                                                                                    type='radio'
                                                                                    id={`inline-radio-3`}
                                                                                    onClick={(event) => (setForm({ ...form, gender: "Other" }))}

                                                                                />
                                                                            </div>

                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                            :
                                                            <div>
                                                                {
                                                                    !nextInfo ?
                                                                        <div>
                                                                            <Row className='padding-mobile'>
                                                                                <Col md="4">
                                                                                    <Form.Group>
                                                                                        <Form.Label style={{ color: "white" }}>First Name</Form.Label>
                                                                                        <Form.Control type="text" onChange={(event) => (setSellerData({ ...sellerData, firstName: event.target.value }))} className="mb-0" id="exampleInputEmail2" placeholder="First Name" autoComplete="off" required />

                                                                                    </Form.Group>
                                                                                </Col>
                                                                                <Col md="4">
                                                                                    <Form.Group>
                                                                                        <Form.Label style={{ color: "white" }}>Last Name</Form.Label>
                                                                                        <Form.Control type="text" onChange={(event) => (setSellerData({ ...sellerData, lastName: event.target.value }))} className="mb-0" id="exampleInputEmail3" placeholder="Last Name" autoComplete="off" required />

                                                                                    </Form.Group>
                                                                                </Col>
                                                                                <Col md="4">
                                                                                    <Form.Group>
                                                                                        <Form.Label style={{ color: "white" }}>E-mail</Form.Label>
                                                                                        <Form.Control style={{ borderRadius: "1rem" }} type="email" onChange={(event) => (setSellerData({ ...sellerData, email: event.target.value }))} className="mb-0" id="exampleInputEmail3" placeholder="Enter email" autoComplete="off" required />

                                                                                    </Form.Group>
                                                                                </Col>

                                                                                <Col md="4" className='mt-4'>
                                                                                    <Autocomplete
                                                                                        disablePortal
                                                                                        id="combo-box-demo"
                                                                                        options={State.getAllStates().filter(state => state.countryCode === 'IN')}
                                                                                        getOptionLabel={(option) => option.name}
                                                                                        onChange={(event, value) => {
                                                                                            if (value !== null) {
                                                                                                // Perform actions when a state is selected
                                                                                                setSellerData({ ...sellerData, state: value?.name })
                                                                                                setSelectedState(value)
                                                                                                // You can perform additional actions here, such as updating state or making API calls
                                                                                            }
                                                                                        }}
                                                                                        sx={{

                                                                                            borderRadius: "1rem",

                                                                                        }}
                                                                                        PaperComponent={({ children }) => (
                                                                                            <Paper style={{ backgroundColor: 'black', color: "#ffffff" }}>{children}</Paper>
                                                                                        )}
                                                                                        renderInput={(params) =>
                                                                                            <TextField
                                                                                                {...params}
                                                                                                label="Select State"
                                                                                                inputProps={{
                                                                                                    ...params.inputProps,
                                                                                                    style: { borderColor: 'white', color: 'white' }, // Change border and value color
                                                                                                }}
                                                                                                InputLabelProps={{
                                                                                                    ...params.InputLabelProps,
                                                                                                    style: { color: 'white' }, // Change label color
                                                                                                }}
                                                                                            />}
                                                                                    />
                                                                                </Col>
                                                                                <Col md="4" className='mt-4'>
                                                                                    <Form.Group>
                                                                                        <Form.Control
                                                                                            type="text"
                                                                                            onChange={(event) => (setSellerData({ ...sellerData, city: event.target.value }))}
                                                                                            className="mb-0"
                                                                                            id="exampleInputEmail3"
                                                                                            placeholder="Area Name"
                                                                                            autoComplete="off"
                                                                                            style={{ height: '55px' }} // Set the desired height

                                                                                        />

                                                                                    </Form.Group>
                                                                                </Col>
                                                                                <Col md="4">
                                                                                    <Form.Group>
                                                                                        <Form.Label style={{ color: "white" }}></Form.Label>
                                                                                        <Form.Control type="text"
                                                                                            onChange={(event) => (setSellerData({ ...sellerData, pincode: event.target.value }))}
                                                                                            id="exampleInputEmail3"
                                                                                            placeholder="PIN Code"
                                                                                            autoComplete="off"

                                                                                            name='pincode'
                                                                                            style={{ height: '55px' }} // Set the desired height

                                                                                        />

                                                                                    </Form.Group>
                                                                                </Col>
                                                                                <Col md="4">
                                                                                    <Form.Group>
                                                                                        <Form.Label style={{ color: "white" }}>Gender</Form.Label>
                                                                                        <div key={`inline-radio`} className="mb-3">
                                                                                            <Form.Check
                                                                                                required
                                                                                                inline
                                                                                                label="Male"
                                                                                                name="gender"
                                                                                                type='radio'
                                                                                                id={`inline-radio-1`}
                                                                                                onClick={(event) => (setSellerData({ ...sellerData, gender: "Male" }))}
                                                                                            />
                                                                                            <Form.Check
                                                                                                required
                                                                                                inline
                                                                                                label="Female"
                                                                                                name="gender"
                                                                                                type='radio'
                                                                                                id={`inline-radio-2`}
                                                                                                onClick={(event) => (setSellerData({ ...sellerData, gender: "Female" }))}

                                                                                            />
                                                                                            <Form.Check
                                                                                                required
                                                                                                inline
                                                                                                label="Other"
                                                                                                name="gender"
                                                                                                type='radio'
                                                                                                id={`inline-radio-3`}
                                                                                                onClick={(event) => (setSellerData({ ...sellerData, gender: "Other" }))}

                                                                                            />
                                                                                        </div>

                                                                                    </Form.Group>
                                                                                </Col>
                                                                                <Col md="4">
                                                                                    <Form.Group>
                                                                                        <Form.Label style={{ color: "white" }}>Phone</Form.Label>
                                                                                        <FormControl fullWidth>
                                                                                            <PhoneInput
                                                                                                labelId="demo-simple-select-label"
                                                                                                country={"in"}
                                                                                                required={true}
                                                                                                // value={form?.phoneNumber}

                                                                                                onChange={(phone, countryCode) => {
                                                                                                    setSellerData({
                                                                                                        ...sellerData,
                                                                                                        mobileNumber:
                                                                                                            // form?.countryCode == "+" + countryCode.dialCode

                                                                                                            (countryCode.dialCode == "91" ? (phone[2] >= 6 ? phone.substring(2) : countryCode.dialCode)
                                                                                                                : phone.substring(2)),
                                                                                                        countryCode: "+" + countryCode.dialCode,
                                                                                                    });
                                                                                                }}
                                                                                                isValid={(value, country) => {
                                                                                                    if (country.dialCode == "91" && value[2] < 6) {
                                                                                                        return 'Invalid Number'
                                                                                                    } else {
                                                                                                        return true;
                                                                                                    }
                                                                                                }}
                                                                                                dropdownClass={styles.phoneInputDropDown}
                                                                                                highlightClass={styles.highlight}
                                                                                                buttonClass={styles.phoneInputButton}
                                                                                                inputClass={styles.phoneInput}
                                                                                            />
                                                                                        </FormControl>
                                                                                    </Form.Group>
                                                                                </Col>
                                                                                <Col md="4">
                                                                                    <Form.Group>
                                                                                        <Form.Label style={{ color: "white" }}>Business Name</Form.Label>
                                                                                        <Form.Control type="text" style={{ height: "49px" }} onChange={(event) => (setSellerData({ ...sellerData, business_name: event.target.value }))} className="mb-0" id="exampleInputEmail2" placeholder="Business Name" autoComplete="off" required />

                                                                                    </Form.Group>
                                                                                </Col>
                                                                                <Col md="4">
                                                                                    <Form.Group>
                                                                                        <FormControl fullWidth>
                                                                                            <Form.Label style={{ color: "white" }}>Business type</Form.Label>

                                                                                            <Autocomplete
                                                                                                disablePortal
                                                                                                id="combo-box-demo"
                                                                                                options={businessType}
                                                                                                onChange={(event, value) => {
                                                                                                    if (value !== null) {
                                                                                                        setSellerData({ ...sellerData, business_type: value?.label });
                                                                                                    }
                                                                                                }}
                                                                                                sx={{
                                                                                                    borderRadius: "1rem",
                                                                                                }}
                                                                                                renderInput={(params) =>
                                                                                                    <TextField
                                                                                                        {...params}
                                                                                                        // label="Movie"
                                                                                                        inputProps={{
                                                                                                            ...params.inputProps,
                                                                                                            style: { borderColor: 'white', color: 'white', height: "17px" }, // Change border and value color

                                                                                                        }}
                                                                                                        InputLabelProps={{
                                                                                                            ...params.InputLabelProps,
                                                                                                            style: { color: 'white' }, // Change label color
                                                                                                        }}
                                                                                                    />}
                                                                                            />
                                                                                        </FormControl>
                                                                                    </Form.Group>
                                                                                </Col>

                                                                                <Col md="4">
                                                                                    <Form.Group>
                                                                                        <Form.Label style={{ color: "white" }}>Established Year</Form.Label>
                                                                                        <Form.Control type="text" onChange={(event) => (setSellerData({ ...sellerData, year_started: event.target.value }))} className="mb-0" id="exampleInputEmail2" placeholder="Established Year" autoComplete="off" required />

                                                                                    </Form.Group>
                                                                                </Col>
                                                                                <Col md="4">
                                                                                    <Form.Group>
                                                                                        <Form.Label style={{ color: "white" }}>Annual Turnover</Form.Label>
                                                                                        <Form.Control type="text" onChange={(event) => (setSellerData({ ...sellerData, annual_turnover: event.target.value }))} className="mb-0" id="exampleInputEmail2" placeholder="Annual Turnover" autoComplete="off" required />

                                                                                    </Form.Group>
                                                                                </Col>
                                                                                <Col md="6">
                                                                                    <Form.Group>
                                                                                        <Form.Label style={{ color: "white" }}>GSTN </Form.Label>
                                                                                        <Form.Control type="text" name='gstnumber ' value={sellerData?.gst_no || " "} onChange={(event) => (setSellerData({ ...sellerData, gst_no: event.target.value }))} className="mb-0" id="GSTN" placeholder="GSTN" autoComplete={"off"} required />

                                                                                    </Form.Group>
                                                                                </Col>
                                                                                <Col md="12">
                                                                                    <Form.Group>
                                                                                        <Form.Label style={{ color: "white" }}>Business Address</Form.Label>
                                                                                        <Form.Control onChange={(event) => (setSellerData({ ...sellerData, address: event.target.value }))} as="textarea" name='description' id="exampleInputEmail2" required></Form.Control>

                                                                                    </Form.Group>
                                                                                </Col>

                                                                                <Col md="6">
                                                                                    <Form.Group>
                                                                                        <Form.Label style={{ color: "white" }}>Password</Form.Label>
                                                                                        <InputGroup className="mb-3">
                                                                                            <Form.Control type="password" onChange={(event) => (setSellerData({ ...sellerData, password: event.target.value }))} className="promocode-input" id="password" placeholder="Password" required />
                                                                                            <Button variant="outline-secondary" onClick={(e) => {
                                                                                                let temp = document.getElementById("password")
                                                                                                if (temp.type == "password") {
                                                                                                    temp.type = "text"
                                                                                                    document.getElementById("password_icon").className = "fa fa-eye"
                                                                                                }
                                                                                                else {
                                                                                                    temp.type = "password"
                                                                                                    document.getElementById("password_icon").className = "fa fa-eye-slash"

                                                                                                }
                                                                                            }} className='promocode-button' style={{ backgroundColor: "black" }}>
                                                                                                <i className="fa fa-eye-slash" id='password_icon' style={{ color: "#ffffff" }}></i>
                                                                                            </Button>
                                                                                            <Form.Control.Feedback type="invalid">
                                                                                                Please enter the password.
                                                                                            </Form.Control.Feedback>       </InputGroup>
                                                                                    </Form.Group>
                                                                                </Col>

                                                                                <Col md="6">
                                                                                    <Form.Group>
                                                                                        <Form.Label style={{ color: "white" }}>Confirm Password</Form.Label>
                                                                                        <InputGroup className="mb-3">
                                                                                            <Form.Control type="password" onChange={(event) => (setSellerData({ ...sellerData, rpassword: event.target.value }))} className="promocode-input" id="confirm_password" placeholder="Password" required />
                                                                                            <Button variant="outline-secondary" onClick={(e) => {
                                                                                                let temp = document.getElementById("confirm_password")
                                                                                                if (temp.type == "password") {
                                                                                                    temp.type = "text"
                                                                                                    document.getElementById("confirm_password_icon").className = "fa fa-eye"
                                                                                                }
                                                                                                else {
                                                                                                    temp.type = "password"
                                                                                                    document.getElementById("confirm_password_icon").className = "fa fa-eye-slash"

                                                                                                }
                                                                                            }} className='promocode-button' style={{ backgroundColor: "black", zIndex: "0" }}>
                                                                                                <i className="fa fa-eye-slash" id='confirm_password_icon' style={{ color: "#ffffff" }}></i>
                                                                                            </Button>
                                                                                            <Form.Control.Feedback type="invalid">
                                                                                                Passwords do not match.
                                                                                            </Form.Control.Feedback>       </InputGroup>
                                                                                    </Form.Group>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            <Row className='padding-mobile'>
                                                                                <Col md="6">
                                                                                    <Form.Group>
                                                                                        <Form.Label style={{ color: "white" }}>IFSC Code</Form.Label>
                                                                                        <div style={{ display: "flex" }}>
                                                                                            <Form.Control type="text" onChange={(event) => (setSellerData({ ...sellerData, ifsc_code: event.target.value }))} className="mb-0" id="exampleInputEmail2" placeholder="IFSC" autoComplete="off" required />
                                                                                            <div className="btn btn-hover ml-2" onClick={() => getBankInfo()}>Check</div>
                                                                                        </div>

                                                                                    </Form.Group>
                                                                                </Col>
                                                                                <Col md="6">
                                                                                    <Form.Group>
                                                                                        <Form.Label style={{ color: "white" }}>Account Holder Name</Form.Label>
                                                                                        <Form.Control type="text" value={sellerData?.acc_holder_name || ""} onChange={(event) => (setSellerData({ ...sellerData, acc_holder_name: event.target.value }))} className="mb-0" id="exampleInputEmail2" placeholder="Account Holder Name" autoComplete="off" name='bank-info' required />

                                                                                    </Form.Group>
                                                                                </Col>
                                                                                <Col md="6">
                                                                                    <Form.Group>
                                                                                        <Form.Label style={{ color: "white" }}>Bank Name</Form.Label>
                                                                                        <Form.Control type="text" disabled value={BankInfo?.data?.BANK || ""} className="mb-0" id="exampleInputEmail2" placeholder="Bank Name" autoComplete="off" required />

                                                                                    </Form.Group>
                                                                                </Col>
                                                                                <Col md="6">
                                                                                    <Form.Group>
                                                                                        <Form.Label style={{ color: "white" }}>Branch</Form.Label>
                                                                                        <Form.Control type="text" disabled value={BankInfo?.data?.BRANCH || ""} className="mb-0" id="exampleInputEmail2" placeholder="Bank Address" autoComplete="off" required />

                                                                                    </Form.Group>
                                                                                </Col>
                                                                                <Col md="6">
                                                                                    <Form.Group>
                                                                                        <Form.Label style={{ color: "white" }}>Account Number</Form.Label>
                                                                                        <Form.Control type="text" onChange={(event) => (setSellerData({ ...sellerData, acc_number: event.target.value }))} className="mb-0" id="exampleInputEmail2" placeholder="Account Number" autoComplete="off" required />

                                                                                    </Form.Group>
                                                                                </Col>
                                                                                <Col md="6">
                                                                                    <Form.Group>
                                                                                        <Form.Label style={{ color: "white" }}>Confirm Account Number</Form.Label>
                                                                                        <Form.Control value={sellerData?.confirm_acc_number || ""} type="text" onChange={(event) => (setSellerData({ ...sellerData, confirm_acc_number: event.target.value }))} className="mb-0" id="exampleInputEmail2" placeholder="Confirm Account Number" autoComplete="off" required />

                                                                                    </Form.Group>
                                                                                </Col>

                                                                            </Row>
                                                                        </div>

                                                                }

                                                            </div>
                                                    }



                                                    {register_message && register_message?.statuscode != 200 && messageTimeout && <Alert variant="danger" style={{ background: "rgb(255,0,0,0.1)", borderColor: "rgb(255,0,0)" }} >
                                                        {/* <Alert.Heading style={{color:"rgb(255,0,0)",fontWeight:"500",fontSize:"18px"}}>Registration unsuccessful</Alert.Heading> */}
                                                        <p style={{ color: "rgb(255,0,0)", fontWeight: "500", fontSize: "14px" }}>
                                                            {register_message?.message}
                                                        </p>
                                                    </Alert>}
                                                    { }
                                                    <div style={{ display: "flex" }}>
                                                        {
                                                            role == "seller" && nextInfo ?
                                                                <div>
                                                                    <Button onClick={() => submitBankInfo()} className="btn btn-hover" disabled={loader ? true : false}>Sign Up</Button>
                                                                    {loader ? <CircularProgress /> : <></>}
                                                                </div>
                                                                : role == "seller" ?
                                                                    <div>
                                                                        <div className="btn btn-hover" onClick={() => handleNextInfo()}>Next</div>


                                                                    </div> : <></>
                                                        }
                                                        {
                                                            role == "customer" &&
                                                            <div>
                                                                <Button type='submit' className="btn btn-hover" disabled={loader ? true : false}>Sign Up</Button>
                                                                {loader ? <CircularProgress /> : <></>}
                                                            </div>

                                                        }

                                                    </div>

                                                </Form>

                                            </div>}
                                    </div>
                                </div>
                                {!otpVerification && <div className="mt-3">
                                    <div className="d-flex justify-content-center links">
                                        Already have an account?
                                        <Link to="/login" className="text-primary ml-2">Sign In</Link>
                                    </div>
                                </div>}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section >
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)