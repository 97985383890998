import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as HomeAction from '../../../actions/HomeActions'
import { Grid, Paper, TextField } from '@mui/material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ROUTES } from '../../../constant/routes';
import * as MovieAction from '../../../actions/MovieAction'
const darkTheme = createTheme({ palette: { mode: 'dark' } });
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3, width: "100%" }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}


const PremiumCategory = () => {
    const [value, setValue] = React.useState(0);
    const dispatch = useDispatch()
    const { getAllCategories, getSubCategory } = bindActionCreators(HomeAction, dispatch)
    const { getSubCategorySearch } = bindActionCreators(MovieAction, dispatch)
    const [category, setCategory] = useState([])
    const [subCategory, setSubCategory] = useState([])
    const [catId, setCatId] = useState("")
    const history = useHistory()
    const [search, setSearch] = useState(null)
    const [searchValue, setSearchValue] = useState("")
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        (async () => {
            const resData = await getAllCategories()
            console.log(resData)
            if (resData?.statuscode == 200) {
                setCategory(resData?.data)
                handleCategory(resData?.data?.[0]?.id)
            }
        })();
    }, [])

    const handleCategory = async (id) => {
        setCatId(id)
        const resData = await getSubCategory({ id: id })
        console.log(resData)

        setSubCategory(resData?.data)
    }

    const handleVideos = (id) => {
        console.log("id", id, catId)
        history.push(ROUTES.videosByCategory, { category: catId, subCategory: id })
    }
    const manageFunction = (e, id, index) => {
        setSearchValue("")
        setSearch(null)
        handleCategory(id)
        handleChange(e, index)
    }
    const handleSearchChange = async (event) => {
        setSearchValue(event.target.value)
        let userObj = {
            // user_id: user_id,
            search: event.target.value
        }
        const resData = await getSubCategorySearch(userObj)
        setSearch(resData?.data)
        console.log(resData)
        if (!event.target.value) {
            setSearch(null)
        }
    }

    return (
        <section className="m-profile setting-wrapper my-responsive">
            {/* <Container className='mb-3'>  */}
                <Grid display={"flex"} sx={{ ml: 2, mr: 2 }} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography variant='h5'>All Categories</Typography>
                    <input
                        autoFocus
                        type="text"
                        name="search"
                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                        aria-required="true"
                        aria-invalid="false"
                        style={{ width: "auto" }}
                        placeholder="Search"
                        value={searchValue}
                        onChange={(event) => handleSearchChange(event)}
                        autoComplete="off"
                    />
                    {/* <h5>Categories</h5> */}

                </Grid>
            {/* </Container> */}
            <ThemeProvider theme={darkTheme}>
                <Box
                    sx={{ flexGrow: 1, bgcolor: 'black', display: 'flex', height: "80vh", width: "100%" }}
                >
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        // onChange={handleChange}
                        aria-label="Vertical tabs example"
                        sx={{
                            borderRight: 1, borderColor: 'divider', '& .Mui-selected': {
                                color: '#ffffff ',
                            }
                        }}
                    >
                        {
                            category.length && category.map((data, index) => (
                                <Tab
                                    key={index}
                                    sx={{ color: 'white',minWidth:250, height: 70, alignItems: "flex-start" }}
                                    onClick={(e) => manageFunction(e, data?.id, index)
                                        // handleCategory(data?.id),
                                        //     handleChange(e, index)
                                    }
                                    label={data?.category_name}
                                    {...a11yProps(index)} />

                            ))
                        }

                    </Tabs>
                    <TabPanel value={value} index={value} style={{ width: "100%" }}>
                        {search?.length ? <Grid>
                            <Typography sx={{ mb: 2 }}>Search results for "{searchValue}"...</Typography>
                            <Grid container spacing={2}>

                                {search?.map((data, index) => (
                                    <Grid item sx={{ cursor: "pointer" }} xs={12} sm={6} lg={3} key={index} onClick={() => handleVideos(data?.id)}>
                                        <Paper sx={{ textAlign: "center", p: 4 }} elevation={24}>
                                            <Typography style={{ margin: 0 }}>{data?.subcategory_name}</Typography>
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid></Grid> :

                            <Grid container spacing={2}>
                                {subCategory?.length ?
                                    subCategory?.map((data, index) => (
                                        <Grid item sx={{ cursor: "pointer" }} xs={12} sm={6} lg={3} key={index} onClick={() => handleVideos(data?.id)}>
                                            <Paper sx={{ textAlign: "center", p: 3 }} elevation={24}>
                                                <Typography style={{ margin: 0 }}>{data?.subcategory_name}</Typography>
                                            </Paper>
                                        </Grid>
                                    ))
                                
                                :
                                <>
                                No result found.
                                </>}
                            </Grid>
                        } </TabPanel>

                </Box>
            </ThemeProvider>
        </section>
    );
}
export default PremiumCategory;