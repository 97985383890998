import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as HomeAction from '../../../actions/HomeActions'
import { Col, Container, Row } from 'react-bootstrap'
import ListTable from '../pages/auth/table'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { ROUTES } from '../../../constant/routes'
import logo from '../../../assets/regal_images/regal_logo.png'
import { Box, Modal, Typography } from '@mui/material'
import { LOCALITY_IMAGE_ENDPOINT } from '../../../constant/ApiList'

const styleforAlert = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "90vh",
    width: 600,
    bgcolor: 'rgb(0,0,0,0.8)',
    border: '2px solid rgb(255,255,255,0.5)',
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
}

export default function Products() {
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
    const dispatch = useDispatch()
    const { getAllProducts } = bindActionCreators(HomeAction, dispatch)
    const [products, setProducts] = useState([])
    const history = useHistory()
    const product_list = useSelector((state) => state?.home.products?.data)
    const [openDlg, setOpenDlg] = useState(false)
    const [details, setDetails] = useState({})

    useEffect(() => {
        (async () => {
            const resData = await getAllProducts({ user: user_id })
            setProducts(resData?.data)
        })();

    }, [])

    var tableData = {
        tableTitle: "Products",
        setSearch: false,

        tableHead: [
            {
                id: "product_name",
                label: "Product Name",
            },
            {
                id: "product_price",
                label: "Product Price",
            },
            {
                id: "product_category",
                label: "Product Category",
            },
            {
                id: "created_at",
                label: "Date",
            },
            {
                id: "btn",
                label: "View",
            },

        ],
        tableBody: [
            {
                id: 0,
                slider_type: "Landscape",
                app_banner: "Landscape",
                web_banner: "Landscape",
                videoType: "Landscape",
                set_sequence: "2",
                status: "Active"
            },
        ],

    };
    useMemo(() => {
        tableData["tableBody"] = product_list && products.map((ele) => ({
            ...ele,
            product_name: ele?.product_name,
            product_price: "₹ " + Number(ele?.product_price).toFixed(2),
            product_category: ele?.product_category?.category_name,
            btn: <button className='my-view-btn' onClick={() => handleDialogue(ele)}>View</button>

        })) || []
    }, [products, openDlg])

    const handleDialogue = (data) => {
        setOpenDlg(true)
        setDetails(data)
        console.log(data)
    }

    const goBack = () => {
        history.push(ROUTES.my_account)
    }
    console.log(openDlg)
    return (
        <>
            <section className="sign-in-page" style={{ marginTop: "5rem" }}>
                <Container >
                    <Row className="justify-content-center align-items-center height-self-center">
                        <Col lg="12" md="12" className="align-self-center">
                            <div className="sign-user_card ">
                                <div className="sign-in-page-data">
                                    <div className="sign-in-from w-100 m-auto">
                                        <p style={{ textAlign: "center", marginBottom: "1rem", fontWeight: "400", fontSize: "24px" }}>All Products</p>
                                        <ListTable
                                            tableData={tableData}
                                            key={"ListTable"}
                                        />
                                        <div style={{ cursor: "pointer" }} onClick={goBack}><i className="fa fa-arrow-left" aria-hidden="true"></i>  Back</div>
                                    </div>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Modal
                open={openDlg}
                onClose={() => setOpenDlg(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={styleforAlert} className="resp-cls">

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={logo} width={"100px"} />
                    </div>
                    <hr />

                    <div className="login-popup" >
                        <div className='resp-1' style={{ display: "flex", justifyContent: "space-evenly",alignItems:"center" }}>
                            <img style={{ height: "150px" }} src={LOCALITY_IMAGE_ENDPOINT + details?.product_image} alt='img' />
                            <div>
                            <span style={{ textAlign: "center", fontSize: "18px" }}>{details?.product_name}</span><br />
                            <span style={{ textAlign: "center", fontSize: "15px",color:"#f49f01" }}>Price : ₹ {Number(details?.product_price).toFixed(2)}</span>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "" }}>
                            <div style={{ display: "inline-grid" }}>

                            </div>
                            <div className='ml-2 mb-2 mt-2'>
                                {/* <div style={{ display: "flex", justifyContent: "center" }}>
                                    <span style={{ textAlign: "center", fontSize: "18px" }}>{details?.product_name}</span><br />
                                </div>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <span style={{ textAlign: "center", fontSize: "15px",color:"#f49f01" }}>Price : ₹ {Number(details?.product_price).toFixed(2)}</span>
                                </div> */}

                                <span style={{ fontSize: "15px" }}>
                                    {details?.description}
                                </span>
                                <div className='mt-2'>
                                    <span style={{ fontSize: "15px", display: "flex" }}>
                                        Category : {details?.product_category?.category_name}
                                    </span>
                                    <span style={{ fontSize: "15px" }}>
                                        Sub Categories :{"  "}
                                        {
                                            details?.product_subcategory?.map((data, index) => (
                                                <span style={{ fontSize: "15px" }} key={index}>{index ? ',' : ''}{data?.subcategory_name}</span>

                                            ))
                                        }

                                    </span>
                                    <div style={{ textAlign: "left" }}>
                                        Hashtags : {"  "}
                                        {details?.product_tag?.map((data, index) => (
                                            <span style={{ fontSize: "15px" }} key={index}>
                                                {index ? ',' : ''}{data?.tag_title}
                                            </span>
                                        ))}

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: "flex", justifyContent: "end", marginTop: "0rem" }}>
                            <button onClick={() => setOpenDlg(false)} className={"btn btn-hover ml-3 iq-button"}>
                                Close
                            </button>
                        </div>
                    </div>

                </Box>
            </Modal>
        </>
    )
}
