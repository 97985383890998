import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as HomeAction from '../../../actions/HomeActions'
import { Button, Col, Container, Row } from 'react-bootstrap'
import Category from '../home/Category'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { ROUTES } from '../../../constant/routes'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import CancelIcon from '@mui/icons-material/Cancel';
import logo from '../../../assets/regal_images/regal_logo.png'
import { Autocomplete, CircularProgress, Grid, Paper, TextField } from '@mui/material'
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { LOCALITY_IMAGE_ENDPOINT } from '../../../constant/ApiList'
import empty from '../../../assets/regal_images/empty-box (1).png'

export default function Locality() {
    const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
    const dispatch = useDispatch()
    const [category, setCategory] = useState([])
    const [isFiltered, setIsFiltered] = useState(false)
    const [allCat, setAllCat] = useState([])
    const { getAllHomeData, getAllCategories, getSubCategory } = bindActionCreators(HomeAction, dispatch)
    const { getFilteredProducts } = HomeAction
    const history = useHistory()
    const [subCat, setSubCat] = useState([])
    const [filterForm, setFilterForm] = useState({
        video_category: "",
        video_subcategory: "",
        start_price: '0',
        end_price: '200000'
    })
    const [loader, setLoader] = useState(false)
    const [filteredProduct, setFilteredProduct] = useState([])
    const [range, setRange] = useState({
        start: 99,
        end: 9999
    })

    useEffect(() => {
        (async () => {
            let userObj = {
                user_id: user_id ? user_id : "null",
                profile_id: profile_id ? profile_id : "null"
            }
            const Allcategories = await getAllCategories()
            console.log("first", Allcategories)
            setAllCat(Allcategories)
            const resData = await getAllHomeData(userObj)
            setCategory(resData?.data)
        })()
    }, [])

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const handleSubcategory = async (data) => {
        // console.log(value)
        const resData = await getSubCategory({ id: data?.id })
        console.log(resData)
        setSubCat(resData)
    }

    const handleChange = (newValue) => {
        setFilterForm({
            ...filterForm,
            start_price: (newValue?.[0])?.toString(),
            end_price: (newValue?.[1])?.toString()
        })
        setRange({
            start: newValue?.[0],
            end: newValue?.[1]
        })
    };
    console.log(filterForm);

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 300 }}
            role="presentation"
            // onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <div onClick={toggleDrawer(anchor, false)} style={{ display: "flex", justifyContent: "end", marginRight: "1rem", marginTop: "1rem" }}>
                <CancelIcon />
            </div>
            <div className='mt-3 mb-3' style={{ display: "flex", justifyContent: "center" }}>
                <img src={logo} alt='logo' style={{ height: "50px" }} />
            </div>
            <Divider sx={{ background: "#ffffff" }} />
            <List>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={allCat?.data}
                    getOptionLabel={(option) => option.category_name}
                    onChange={(event, value) => {
                        if (value !== null) {
                            setFilterForm({ ...filterForm, video_category: value?.id });
                            handleSubcategory(value)
                        }
                    }}
                    sx={{
                        borderRadius: "1rem",
                        width: "90%",
                        margin: "auto",
                        marginTop: "1rem"
                    }}
                    PaperComponent={({ children }) => (
                        <Paper style={{ backgroundColor: 'black', color: "#ffffff" }}>{children}</Paper>
                    )}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Select Product Category"
                            inputProps={{
                                ...params.inputProps,
                                style: { borderColor: 'white', color: 'white' }, // Change border and value color
                            }}
                            InputLabelProps={{
                                ...params.InputLabelProps,
                                style: { color: 'white' }, // Change label color
                            }}
                        />}
                />

                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={subCat?.data?.length ? subCat?.data : []}
                    getOptionLabel={(option) => option?.subcategory_name}
                    onChange={(event, value) => {
                        if (value !== null) {
                            setFilterForm({ ...filterForm, video_subcategory: value?.id });
                            // handleSubcategory(value)
                        }
                    }}
                    sx={{
                        borderRadius: "1rem",
                        width: "90%",
                        margin: "auto",
                        marginTop: "2rem"
                    }}
                    PaperComponent={({ children }) => (
                        <Paper style={{ backgroundColor: 'black', color: "#ffffff" }}>{children}</Paper>
                    )}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Select Product Subcategory"
                            inputProps={{
                                ...params.inputProps,
                                style: { borderColor: 'white', color: 'white' }, // Change border and value color
                            }}
                            InputLabelProps={{
                                ...params.InputLabelProps,
                                style: { color: 'white' }, // Change label color
                            }}
                        />}
                />
                <div style={{ marginTop: "2rem", textAlign: "center" }}>
                    <p>Select price range</p>
                    <div style={{ width: "90%", margin: "auto" }}>
                        <RangeSlider
                            id="range-slider-yellow"
                            min={99}
                            max={200000}
                            step={5}
                            onInput={handleChange}
                            value={[range?.start, range?.end]}

                        />
                    </div>
                    <div style={{ marginTop: "1rem" }}>
                        {/* Selected Range <br/> */}
                        ₹ {range?.start} - ₹ {range?.end}
                    </div>

                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button onClick={() => handleFilter()} className="btn btn-hover mt-3 iq-button" >
                        Apply
                    </Button>
                </div>

            </List>
        </Box>
    );

    const handleFilter = async () => {
        setIsFiltered(true)
        setState({ ...state, ['right']: false });
        const resData = await getFilteredProducts(filterForm);
        console.log(resData)
        setFilteredProduct(resData?.data)
    }

    const handleClearFilter = () => {
        setIsFiltered(false)

    }

    return (
        <>
            <div
                style={{
                    background: "#ffffff",
                    minWidth: "60px",
                    textAlign: "center",
                    position: "absolute",
                    right: "40px",
                    bottom: "20px",
                    padding: "10px",
                    borderRadius: "1rem",
                    color: "#000000",
                    fontWeight: "900",
                    zIndex: "999",
                    cursor: "pointer"
                }}>
                {
                    !isFiltered ?
                        <div onClick={toggleDrawer('right', true)} style={{ display: "flex", alignItems: "center" }}>
                            <FilterAltIcon />
                            <span style={{ fontSize: "17px" }}>Filter Videos</span>
                        </div>
                        :
                        <div onClick={handleClearFilter} style={{ display: "flex", alignItems: "center" }}>
                            <CancelIcon />
                            <span style={{ fontSize: "17px" }}> Clear</span>
                        </div>
                }


            </div>
            <Drawer
                anchor={'right'}
                open={state['right']}
                onClose={toggleDrawer('right', false)}
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: '#000000d6',
                        color: "#ffffff"
                    },
                }}
            >
                {list('right')}
            </Drawer>
            <section className="m-profile setting-wrapper">
                <Container>
                    <div style={{ textAlign: "center" }}>
                        <Row>
                            <Col md="8" className='mt-2 mb-3'>
                                <h4 style={{ textAlign: "start" }} className="main-title mb-0 mt-3">All Products Video</h4>

                            </Col>

                        </Row>
                    </div>

                </Container>
                {
                    !isFiltered ?
                        category?.length ?
                            category?.map((data, index) => (
                                <div key={index}>
                                    <Category category={data} />

                                </div>
                            ))
                            :
                            <div style={{ display: "flex", justifyContent: "center", marginTop: "5rem" }}>
                                <CircularProgress />
                            </div>
                        :
                        <Container>

                            <Grid container spacing={2}>
                                {
                                    filteredProduct?.length ?
                                        filteredProduct?.map((data, index) => (
                                            <Grid style={{ cursor: "pointer" }} item lg={4} xl={4} md={6} sm={6} xs={12} key={index} className='grid-img-cls trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction'>
                                                <div onClick={() => history.push(ROUTES.moviedetails + '/' + data?.slug + `/image-thumb/${LOCALITY_IMAGE_ENDPOINT}${data?.video_poster}`)} className="image-container">
                                                    <img src={`${LOCALITY_IMAGE_ENDPOINT + data?.video_poster}`} className="series-img" alt={data?.video_name} />
                                                    <div className="image-overlay">{data?.video_name}</div>

                                                </div>
                                            </Grid>
                                        ))
                                        :
                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <img src={empty} alt='empty' style={{ height: "60px" }} />
                                                <p className='pt-4'> Sorry, No result found.</p>
                                                {/* <CircularProgress /> */}
                                            </div>
                                        </Grid>
                                }

                            </Grid>
                        </Container>
                }

            </section>
        </>
    )
}
