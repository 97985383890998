import React, { useState, useEffect, useMemo } from 'react'
import { Container, Button, Row, Col, Form } from 'react-bootstrap'

import { Link, useHistory } from 'react-router-dom'
import "./form.css"
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { rtlModeAction, getRtlMode } from '../../../../store/mode/rtlmode'
import { useDispatch } from 'react-redux';
import { IMAGE_ENDPOINT } from '../../../../constant/ApiList';
import { user_details_web } from '../../../../actions/HomeActions';
import { ROUTES } from '../../../../constant/routes';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Grid } from '@mui/material';
import ListAltIcon from '@mui/icons-material/ListAlt';

const mapStateToProps = (state) => {
    return {
        rtlMode: getRtlMode(state)
    };


}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            rtlModeAction
        },
        dispatch
    )
})
const My_Account = (props) => {
    const user = JSON.parse(sessionStorage.getItem('user'))
    const profile = JSON.parse(sessionStorage.getItem('profile'))
    console.log(user, "acc")
    const dispatch = useDispatch()
    let history = useHistory()
    const [show, setShow] = useState(false);
    const user_details = useSelector((state) => state.home.user_details?.data)
    const user_plan_expired = useSelector((state) => state.home.user_details)

    useEffect(() => {

        dispatch(user_details_web({ user_id: user?.id }))

    }, [])
    console.log(user_plan_expired, "my_account")

    const handleClick = () => {
        history.push('/profile_selection', { back: true })
    }

    return (
        <>



            <section className="m-profile setting-wrapper mt-3">
                <Container>
                    <Row style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                        <Col lg="8">
                            <div className='mb-2' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                                <h5 className="main-title">Personal Details</h5>
                                {/* <Link to={ROUTES.edit_account} style={{ color: "#ffc328" }}>
                                    <i className="fa fa-pencil-square" style={{ fontSize: "40px" }} aria-hidden="true"></i>
                                </Link> */}
                            </div>
                            <div className="" style={{ border: "1px solid #ffffff", padding: "20px", borderRadius: "0.7rem" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        Name
                                    </Grid>
                                    <Grid item xs={8}>
                                        {user?.firstName + " " + user?.lastName}
                                    </Grid>
                                    <Grid item xs={4}>
                                        Email
                                    </Grid>
                                    <Grid item xs={8}>
                                        {user?.email}
                                    </Grid>
                                    <Grid item xs={4}>
                                        Mobile No.
                                    </Grid>
                                    <Grid item xs={8}>
                                        {user?.mobileNumber}
                                    </Grid>
                                    <Grid item xs={4}>
                                        Role
                                    </Grid>
                                    <Grid item xs={8}>
                                        {(user?.Role).toUpperCase()}
                                    </Grid>

                                </Grid>
                            </div>
                            {
                                user?.Role == "seller" &&
                                <div className="mt-2" style={{ border: "1px solid #ffffff", padding: "20px", borderRadius: "0.7rem" }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                                            Business Information
                                        </Grid>
                                        <Grid item xs={4}>
                                            Business Name
                                        </Grid>
                                        <Grid item xs={8}>
                                            {user?.business_name}
                                        </Grid>
                                        <Grid item xs={4}>
                                            Address
                                        </Grid>
                                        <Grid item xs={8}>
                                            {user?.address}
                                        </Grid>
                                        <Grid item xs={4}>
                                            Business Type
                                        </Grid>
                                        <Grid item xs={8}>
                                            {user?.business_type}
                                        </Grid>

                                    </Grid>
                                </div>
                            }

                            {/* <h5 className="main-title mb-2 mt-3">Plan Details</h5> */}
                            <div className="mt-2" style={{ border: "1px solid #ffffff", padding: "20px", borderRadius: "0.7rem" }}>
                                <Grid container spacing={2}>

                                    <Grid item xs={user?.Role == "seller" ? 6 : 12} >
                                        <Link to="/change_password">
                                            <div style={{ display: "flex", justifyContent: "center", padding: "10px", alignItems: "center", border: "1px solid #ffffff", borderRadius: "1rem" }}>
                                                <LockOpenIcon />
                                                <span className='pl-2'>Change Password</span>
                                            </div>
                                        </Link>
                                    </Grid>
                                    {
                                        user?.Role == "seller" &&
                                        <Grid item xs={6} >
                                            <Link to={ROUTES.allProducts}>
                                                <div style={{ display: "flex", justifyContent: "center", padding: "10px", alignItems: "center", border: "1px solid #ffffff", borderRadius: "1rem" }}>
                                                    <ListAltIcon />
                                                    <span className='pl-2'>All Products</span>
                                                </div>
                                            </Link>
                                        </Grid>
                                    }

                                </Grid>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(My_Account)