import { param } from 'jquery';
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import * as MovieAction from '../../../actions/MovieAction'
import { Container } from 'react-bootstrap';
import { Grid } from '@mui/material';
import { LOCALITY_IMAGE_ENDPOINT } from '../../../constant/ApiList';
import empty from '../../../assets/regal_images/empty-box (1).png'
import { ROUTES } from '../../../constant/routes';

export default function VideoByArea() {
    const prams = useParams()
    const { getVideosByArea } = MovieAction
    const [videos, setVideos] = useState({})
    const history = useHistory()
    useEffect(() => {
        (async () => {
            if (prams?.slug) {
                const resData = await getVideosByArea(prams?.slug)
                console.log(resData)
                setVideos(resData)
            }
        })();
    }, [prams?.slug])

    return (
        <>
            <section className="m-profile setting-wrapper">
                <Container>

                <h4 className="main-title mb-5 mt-3">Filtered Videos</h4>

                    <Grid container spacing={2}>
                        {
                            videos?.data?.length ?
                            videos?.data?.map((data, index) => (
                                    <Grid style={{ cursor: "pointer" }} item lg={4} xl={4} md={6} sm={6} xs={12} key={index} className='grid-img-cls trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction'>
                                        <div onClick={() => history.push(ROUTES.moviedetails + '/' + data?.slug)} className="image-container">
                                            <img src={`${LOCALITY_IMAGE_ENDPOINT + data?.video_poster}`} className="series-img" alt={data?.movie_name} />
                                            <div className="image-overlay">{data?.video_name}</div>

                                        </div>
                                    </Grid>
                                ))
                                :
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <img src={empty} alt='empty' style={{ height: "60px" }} />
                                        <p className='pt-4'> Sorry, No result found.</p>
                                        {/* <CircularProgress /> */}
                                    </div>
                                </Grid>
                        }

                    </Grid>
                </Container>
            </section>
        </>
    )
}
